import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import GetCurrency, { countryCode, currencyID } from "./services";
import moment from "moment-timezone";
import { replaceAll } from "./common";
import { toast } from "react-toastify";
import { loginUrl } from "../config/config";
import { handleLogout } from "../common/Functions";
import Cookies from "js-cookie";

// Can give Id to toaster messages to prevent duplication
export const successToaster = (message, id = null) => {
	toast.success(message, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		icon: false,
		draggable: true,
		style: { color: "#fff" },
		...(id != null && { toastId: id })
	});
};

// Can give Id to toaster messages to prevent duplication
export const errorToaster = (error, id = null) => {
	toast.error(error, {
		position: "top-right",
		autoClose: 5000,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: true,
		draggable: true,
		icon: false,
		style: { color: "#fff" },
		...(id != null && { toastId: id })
	});
};

export function countInArray(array, what) {
	var count = 0;
	for (var i = 0; i < array.length; i++) {
		if (array[i] === what) {
			count++;
		}
	}
	return count;
}

export const handleKeypress = (e, handleSubmit) => {
	//it triggers by pressing the enter key
	if (e.charCode === 13) {
		handleSubmit();
	}
};

// Helper function to check if the description is valid
export const isValidDescription = description => {
	// Check if description is null, empty, or only contains empty <p> tags
	return description && !/^<p>\s*<\/p>$/.test(description.trim());
};

/********* Checkout page utils **********/

//Add custom forms/non default forms to misc
export const formatObject = (formData, forms, type = "regDetails") => {
	let newFormData = {};

	const formValue = (value, isNotCustom = true) => {
		if (typeof value === "boolean") return value;
		else if (
			(`${value}` === "" || (typeof value === "array" && _.isEmpty(value))) &&
			isNotCustom
		)
			return null;
		else return `${value}`;
	};

	forms.map(form => {
		if (form.form_id) {
			const formName = form.form_name;
			const formId = form.form_id;
			const shortName = form.er_column_mapping;
			const customForm = form.er_column_mapping === "custom_forms";

			let jsonFormName = formName.toLowerCase().replace(" ", "_");
			//email address should be sent as "email_id" to Backend
			if (jsonFormName === "email_address") {
				jsonFormName = "email_id";
			}
			//store defualt forms in newFormData and the rest of the custom forms in misc (also dont store event consent to custom forms)
			if (form.er_column_mapping !== "custom_forms") {
				newFormData = {
					...newFormData,
					[shortName]: formValue(formData[shortName])
				};
			} else if (
				!form.is_event_consent &&
				form.er_column_mapping === "custom_forms" &&
				type === "regDetails"
			) {
				newFormData["custom_forms"] = {
					...newFormData["custom_forms"],
					[formId]: formValue(formData[formId])
				};
			} else if (!form.is_default && type === "reviewDetails") {
				newFormData["custom_forms"] = {
					...newFormData["custom_forms"],
					[formId]: formValue(formData[formId])
				};
			}
		}
	});

	for (const formName in formData) {
		if (formData.hasOwnProperty(formName)) {
			//store the extra details needed for Backend
			if (
				formName === "dial_code" ||
				formName === "country_code" ||
				formName === "wa_dial_code" ||
				formName === "email_verification_status" ||
				formName === "wa_country_code" ||
				formName === "whatsapp_consent" ||
				formName === "country"
			) {
				newFormData = { ...newFormData, [formName]: formData[formName] };
			}
		}
	}

	return newFormData;
};

//To create final data to pass into to registration_details of the data
export const createRegData = (dataObj, selectedTickets, refferal = null) => {
	const regDetails = {};

	for (const ticket in dataObj) {
		if (dataObj.hasOwnProperty(ticket)) {
			const ticketId = ticket.split("-")[0]; //Split Ticket Id
			const no = ticket.split("-")[1]; //Split the ticket number
			const currentTicket = selectedTickets.filter(
				ticket => ticket.ticket_id === parseInt(ticketId)
			)[0];
			if (currentTicket) {
				/****** THIS IS TO CHECK IF THE TICKET CAN HAVE A SINGLE BUYER FOR MULTIPLE TICKET ********/
				if (currentTicket?.allow_single_buyer && no >= 1) continue;
				/******************************************************************************************/

				const forms = currentTicket.forms;
				if (!regDetails[ticketId]) {
					regDetails[ticketId] = [];
				} else {
					regDetails[ticketId] = [...regDetails[ticketId]];
				}
				regDetails[ticketId][no] = formatObject(dataObj[ticket], forms);
				if (currentTicket.allow_single_buyer) {
					regDetails[ticketId][no].quantity = currentTicket.totalSelected;
					regDetails[ticketId][no].allow_single_buyer = currentTicket.allow_single_buyer;
					regDetails[ticketId][no].free_ticket = currentTicket.ticket_type === 1;
				}
				if (currentTicket.ticket_type === 3)
					regDetails[ticketId][no].donation_price = currentTicket.donation_price;

				if (refferal) {
					selectedTickets.map(ticket => {
						if (ticket.is_referral_ticket) {
							regDetails[ticketId][no] = {
								...regDetails[ticketId][no],
								...refferal
							};
						}
					});
				}
			}
		}
	}

	return regDetails;
};

//To convert waitlist data to formik supported values
export const convertToFormikValues = waitlistDetails => {
	const formikValues = {};

	const forms = waitlistDetails.forms.sort((a, b) => {
		// Sort the forms by from order
		return a.form_order - b.form_order;
	});

	// formikValues = {CreateData(forms, waitlistDetails)}

	return CreateData(forms, waitlistDetails);
};

export const CreateData = (forms, waitlistDetails) => {
	const initialObj = {};
	forms.forEach(form => {
		if (form.er_column_mapping === "custom_forms" && waitlistDetails.custom_forms) {
			// custom forms
			initialObj[form.form_id] =
				waitlistDetails.custom_forms[form.form_id] !== null
					? waitlistDetails.custom_forms[form.form_id]
					: "";
		} else if (form.form_type === 3 && form.er_column_mapping === "email_id") {
			initialObj["email_verification_status "] = waitlistDetails.email_verification_status
				? waitlistDetails.email_verification_status
				: 9;
		} else if (form.form_type === 5 && form.er_column_mapping === "phone_number") {
			initialObj["dial_code"] = waitlistDetails.dial_code ? waitlistDetails.dial_code : "+91";
			initialObj["country_code"] = waitlistDetails.country_code
				? waitlistDetails.country_code
				: "in";
			initialObj["country"] = waitlistDetails.country ? waitlistDetails.country : null;
		} else if (form.form_type === 5 && form.er_column_mapping === "whatsapp_number") {
			initialObj["whatsapp_consent"] = waitlistDetails.whatsapp_consent;
			initialObj["wa_dial_code"] = waitlistDetails.wa_dial_code;
			initialObj["wa_country_code"] = waitlistDetails.wa_country_code;
		}

		Object.keys(waitlistDetails).forEach((key, i) => {
			if (form.er_column_mapping !== "custom_forms") {
				if (key.toLowerCase() === form.er_column_mapping.toLowerCase())
					initialObj[form.er_column_mapping] =
						waitlistDetails[key] !== null ? waitlistDetails[key] : "";
			}
		});
	});
	return initialObj;
};

//This will create an array of objects (objects are the attendee details etc) so it can be consumed by reviewtickets component
export const createDataForReview = (dataObj, selectedTickets) => {
	let reviewDetails = [];

	Object.keys(dataObj).forEach((ticketObj, i) => {
		if (ticketObj.split("-").length >= 2) {
			const ticketId = ticketObj.split("-")[0]; //Split Ticket Id
			const no = ticketObj.split("-")[1]; //Split the ticket number
			const forms = selectedTickets.filter(
				ticket => ticket.ticket_id === parseInt(ticketId)
			)?.[0]?.forms;
			const ticketName = selectedTickets.filter(
				ticket => ticket.ticket_id === parseInt(ticketId)
			)?.[0]?.ticket_name;
			const totalSelected = selectedTickets.filter(
				ticket => ticket.ticket_id === parseInt(ticketId)
			)?.[0]?.totalSelected;
			//Create an object in new format, to make it easier to consume from reviewTickets
			const formDataReview = formatObject(dataObj[ticketObj], forms, "reviewDetails");
			const newDetailsObj = { ...formDataReview };

			newDetailsObj["ticket_name"] = ticketName;
			newDetailsObj["totalSelected"] = totalSelected;
			newDetailsObj["ticket_no"] = parseInt(no) + 1;
			reviewDetails = [...reviewDetails, newDetailsObj];
		}
	});

	return reviewDetails;
};

export const CreateInitialData = (forms, ticket, consents) => {
	const formData = {};
	forms.forEach(form => {
		if (!form.form_id) return;

		if (form.er_column_mapping === "custom_forms") {
			formData[form.form_id] = "";
			if (form.form_type === 10) formData[form.form_id] = [];
			if (form.form_type === 9) formData[form.form_id] = false;
			if (form.form_type === 5) {
				formData["country_code"] = countryCode[`${moment.tz.guess()}`]
					? countryCode[`${moment.tz.guess()}`].toLowerCase()
					: "in";
				formData["dial_code"] = formData["country_code"] === "in" ? "+91" : "";
				formData[form.form_id] = "";
			}
		} else {
			formData[form.er_column_mapping] = "";
			if (form.form_type === 3 && form.er_column_mapping === "email_id") {
				formData["email_verification_status"] = 9;
			}
			if (form.form_type === 5 && form.er_column_mapping === "phone_number") {
				formData["country_code"] = countryCode[`${moment.tz.guess()}`]
					? countryCode[`${moment.tz.guess()}`].toLowerCase()
					: "in";
				formData["dial_code"] = formData["country_code"] === "in" ? "+91" : "";
				formData[form.er_column_mapping] = "";
			}
			if (form.form_type === 5 && form.er_column_mapping === "whatsapp_number") {
				formData["wa_country_code"] = countryCode[`${moment.tz.guess()}`]
					? countryCode[`${moment.tz.guess()}`].toLowerCase()
					: "in";
				formData["wa_dial_code"] = formData["wa_country_code"] === "in" ? "+91" : "";
				formData["whatsapp_consent"] = false;
				formData[form.er_column_mapping] = "";
			}
			if (form.form_type === 10) formData[form.er_column_mapping] = [];
			if (form.form_type === 9) formData[form.er_column_mapping] = false;
		}
	});
	return formData;
};

// To create data to pass through formik initialData
export const createFormikData = (selectedTickets, consents = []) => {
	const formikData = {
		visibility: {}
	};
	selectedTickets.map((ticket, i) => {
		const allTicket = [...Array(ticket.totalSelected).keys()];
		const forms = ticket.forms.sort((a, b) => {
			// Sort the forms by from order
			return a.form_order - b.form_order;
		});
		allTicket.map(curNo => {
			if (ticket.allow_single_buyer && curNo > 0) return;
			formikData[`${ticket.ticket_id}-${curNo}`] = CreateInitialData(forms, ticket, consents);
			formikData.visibility[`${ticket.ticket_id}-${curNo}`] = {};
			forms.forEach(form => {
				formikData.visibility[`${ticket.ticket_id}-${curNo}`][form.form_id] =
					form.form_visibility;
			});
		});
	});

	return formikData;
};

// To create data to pass through formik duplicates_allowed
export const duplicatesData = selectedTickets => {
	const duplicatesData = {};

	selectedTickets.map((ticket, i) => {
		const allTicket = [...Array(ticket.totalSelected).keys()];
		const forms = ticket.forms.sort((a, b) => {
			// Sort the forms by from order
			return a.form_order - b.form_order;
		});
		allTicket.map(curNo => {
			duplicatesData[`${ticket.ticket_id}-${curNo}`] = ticket.duplicates_allowed;
		});
	});

	return duplicatesData;
};

// Add consent to ticket forms
export const addConsentToForms = (tickets, consents) => {
	const newTickets = [...tickets];

	tickets.map((ticket, i) => {
		const consentForms = [];
		consents.map((consent, i) => {
			if (consent.consent_name !== "KonfHub Subscription") {
				const consentData = {
					form_id: consent.consent_id,
					form_name: consent.consent_name,
					form_options: [],
					form_order: ticket.forms.length + (i + 1),
					form_type: 9,
					is_default: false,
					is_checked: consent.is_checked,
					is_mandatory: consent.is_mandatory,
					rich_text_form: consent.consent_text,
					is_event_consent: true
				};
				consentForms.push(consentData);
			}
		});
		newTickets[i].forms = [...newTickets[i].forms, ...consentForms];
	});

	return newTickets;
};

export const checkIfFreeTicketAndReferral = (query, ticket) => {
	//check if the ticket is a free refferal ticket
	if (query && query.referred_by && ticket.ticket_price === 0 && ticket.is_referral_ticket)
		return true;
	else return false;
};

export const checkIfExpired = (pickedTickets, eventDetails, setTicketExpired) => {
	const ticket = pickedTickets[0];
	var todaysDate = moment().valueOf(); // convert utc to local
	const ticketStartDate = moment.utc(`${ticket.start_timestamp}`).local().valueOf();
	const ticketEndDate = moment.utc(`${ticket.end_timestamp}`).local().valueOf();
	const eventEndDate = moment
		.utc(`${eventDetails.end_date} ${eventDetails.end_time}`)
		.local()
		.valueOf();

	if (
		todaysDate > eventEndDate ||
		todaysDate > ticketEndDate ||
		(ticket.sold_out && !ticket.waitlist_enabled) ||
		(ticket.remaining_count <= 0 && !ticket.waitlist_enabled)
	) {
		setTicketExpired(1);
	} else if (ticketStartDate > todaysDate) setTicketExpired(3);
};

//To return currency Id for payment
export const getCurrencyId = currencyName => {
	return Object.keys(currencyID).find(key => currencyID[key] === currencyName);
};

// To return payment method type no
export const getPaymentType = (paymentMethod, type = "pg_number") => {
	const PAYMENT_METHOD_TYPES = {
		1: "upi",
		2: "cards",
		3: "net_banking",
		4: "stripe_cards",
		5: "wallet"
	};

	if (type == "pg_number") {
		// To return the payment method number (eg: 1, 2, 3 etc.)
		return Object.keys(PAYMENT_METHOD_TYPES).find(
			key => PAYMENT_METHOD_TYPES[key] === paymentMethod
		);
	} else if (type == "pg_type_name") {
		// To return the payment method string (eg: upi, cards etc.)
		return Object.values(PAYMENT_METHOD_TYPES).find(
			values => values === PAYMENT_METHOD_TYPES[paymentMethod]
		);
	}
};

//Add team details for team based into capture api
export const addTeamDetailsToCapture = teamRegType => {
	const teamDetails = {
		...(!!teamRegType && {
			team_details: {
				...(teamRegType === 1
					? {
						team_name: sessionStorage.getItem("team_name"),
						team_lead_name: sessionStorage.getItem("team_lead_name"),
						team_lead_email: sessionStorage.getItem("team_lead_email")
					}
					: {
						team_name: sessionStorage.getItem("team_name"),
						team_id: parseInt(sessionStorage.getItem("team_id"))
					})
			}
		})
	};

	return teamDetails;
};

export const removeTeamDetailsFromSessionStorage = teamRegType => {
	sessionStorage.removeItem("team_name");
	sessionStorage.removeItem("team_lead_name");
	sessionStorage.removeItem("team_lead_email");
	sessionStorage.removeItem("team_name");
	sessionStorage.removeItem("team_id");
	sessionStorage.removeItem("team_attendee_count");
	sessionStorage.removeItem("teamRegType");
	sessionStorage.removeItem("isEventPageTeamComp");
};

const setFieldTouched = (formik, ticketName, formName, value) => {
	formik.setFieldTouched(ticketName, {
		...formik.touched[ticketName],
		[formName]: value
	});
};

/************* ***************/

export const clickOutside = (ref, onClickOutside) => {
	// Function to close dropdown when clicked anywhere outside
	useEffect(() => {
		/**
		 * Invoke Function onClick outside of element
		 */
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside();
			}
		}
		// Bind
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			// dispose
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, onClickOutside]);
};

/******* google analytics util ********/

export const initGA = id => {
	// if (process.env.NODE_ENV === "production") {
	ReactGA.initialize(id);
	// }
};

let formsValue = null;
export const setAllForms = forms => {
	formsValue = forms;
};

export const getAllForms = () => {
	return formsValue;
};

/******* conditional form check ********/
export const validateConditionalForms = (
	formRules,
	formik,
	inputVal,
	ticketName,
	formID,
	formKey,
	custom_consent_formKey
) => {
	const visibilityChanges = {}; // Track visibility changes for all forms

	if (typeof formRules === "string") return;

	// Helper to evaluate a single condition
	const evaluateCondition = (cond, form_id, condition_value) => {
		let testValue;
		if (form_id === formID) {
			testValue = inputVal;
		} else {
			const currentForm = formsValue.find(form => form.form_id === form_id);
			if (currentForm)
				testValue =
					formik.values[ticketName][
					currentForm.er_column_mapping === "custom_forms"
						? currentForm.form_id
						: currentForm.er_column_mapping
					];
		}

		// Ensure testValue is a string before performing string operations
		if (typeof testValue !== "string") {
			testValue = testValue?.toString() || ""; // Convert to string or default to an empty string
		}

		switch (cond) {
			case "CONTAIN":
				return testValue?.includes(condition_value);
			case "EQUALS_TO":
				return testValue?.trim() === condition_value.trim();
			case "NOT_EQUALS_TO":
				return testValue?.trim() !== condition_value.trim();
			case "DOES_NOT_CONTAIN":
				return !testValue?.includes(condition_value);
			case "IS_NULL":
				return testValue === null || testValue === "" || testValue === "false";
			case "NOT_NULL":
				return testValue !== null && testValue !== "" && testValue !== "false";
			case "LESSER_THAN":
				return parseFloat(testValue) < parseFloat(condition_value);
			case "GREATER_THAN":
				return parseFloat(testValue) > parseFloat(condition_value);
			default:
				return false;
		}
	};

	// Iterate through all form rules and conditions
	formRules?.forEach(rule => {
		const { conditions, actions, conditions_operand } = rule;
		let conditionsMet = false;

		// Evaluate conditions based on the operand (ALL or ANY)
		if (conditions_operand === "ALL") {
			conditionsMet = conditions.every(({ condition, form_id, condition_value }) =>
				evaluateCondition(condition, form_id, condition_value)
			);
		} else if (conditions_operand === "ANY") {
			conditionsMet = conditions.some(({ condition, form_id, condition_value }) =>
				evaluateCondition(condition, form_id, condition_value)
			);
		}

		// Collect visibility changes based on actions
		actions.forEach(action => {
			const { form_id, action_type } = action;

			// If the form is mentioned in multiple rules, we only apply the first change or give higher priority to certain actions (e.g., HIDE over SHOW)
			if (!visibilityChanges[form_id]) {
				visibilityChanges[form_id] = conditionsMet
					? action_type === "SHOW"
					: action_type === "HIDE";
			} else {
				// Prioritize HIDE over SHOW if the form appears in multiple rules
				if (action_type === "HIDE") {
					visibilityChanges[form_id] = false;
				}
			}
		});
	});

	// Apply visibility changes after all rules have been processed
	Object.keys(visibilityChanges).forEach(form_id => {
		const shouldBeVisible = visibilityChanges[form_id];
		if (shouldBeVisible && formik.values.visibility[ticketName][form_id] !== true) {
			formik.setFieldValue(`visibility.${ticketName}.${form_id}`, true);
		} else if (!shouldBeVisible && formik.values.visibility[ticketName][form_id] !== false) {
			formik.setFieldValue(`visibility.${ticketName}.${form_id}`, false);
			formik.setFieldValue(`${ticketName}.${form_id}`, ""); // Clear the value if hidden
		}
	});

	// Update the field values
	if (formKey === "consent" || custom_consent_formKey === "custom_consent") {
		formik.setFieldTouched(`${ticketName}.${formKey}`, true);
		formik.setFieldValue(`${ticketName}.${formKey}`, inputVal);
	}
};

/**** Function to return only the differences of initial values & values object *****/

export const returnObjDiff = (initialValues, values) => {
	let diffObj = {};
	Object.keys(values).map(key => {
		if (!initialValues.hasOwnProperty(key)) {
			diffObj = { ...diffObj, [key]: values[key] };
		} else if (initialValues[key] !== values[key]) {
			diffObj = { ...diffObj, [key]: values[key] };
		}
	});

	return diffObj;
};

export const addNullForEmptyString = data => {
	let updatedValues = {};
	Object.keys(data).map(key => {
		if (data[key] === "") updatedValues = { ...updatedValues, [key]: null };
		else updatedValues = { ...updatedValues, [key]: data[key] };
	});
	return updatedValues;
};

export const addEmptyStringForNullValues = data => {
	let updatedValues = {};
	Object.keys(data).map(key => {
		if (data[key] === null) updatedValues = { ...updatedValues, [key]: "" };
		else updatedValues = { ...updatedValues, [key]: data[key] };
	});
	return updatedValues;
};

export const paymentErrorHelper = errMessage => {
	let errorCode = "unexpected";
	if (errMessage === "Ticket has expired") errorCode = "ASC-3";
	else if (errMessage === "ticket is not accessible") errorCode = "ASC-20";
	else if (errMessage === "the coupon used for the transaction is unfortunately expired.")
		errorCode = "ASC-9";
	else if (
		errMessage ===
		"the coupon used for the transaction is not applicable for any of the tickets purchased."
	)
		errorCode = "ASC-11";
	else if (
		errMessage ===
		"the coupon used for the transaction is unfortunately exhausted and can not be used anymore."
	)
		errorCode = "ASC-12";

	return errorCode;
};

export const getQuery = query => {
	const newQuery = { ...query };
	if (newQuery.hasOwnProperty("eventUrl")) {
		newQuery.eventUrl = newQuery.eventUrl.toLowerCase();
		return newQuery;
	} else return query;
};

const stripHtmlTags = htmlString => {
	let result = htmlString.replace(/<\/?[^>]+(>|$)/g, (tag) => {
		return tag.startsWith("<a ") || tag === "</a>" ? tag : "";
	});

	result = result.replace(/<a\s+([^>]*href=["'][^"']+["'][^>]*)>/g, (match, p1) => {
		return `<a ${p1} target="_blank" style="color:blue;">`;
	});

	return result;
};


export const formNameValue = (val, mandatory) => {
	if (mandatory) {
		return React.createElement("span", {
			style: { display: "flex" },
			dangerouslySetInnerHTML: {
				__html: `${stripHtmlTags(val?.replace(/(?:\r\n|\r|\n)/g, "") || "")}*`
			}
		});
	} else {
		return React.createElement("span", {
			style: {
				display: "inline-block",
				textAlign: "left",
				verticalAlign: "middle"
			},
			dangerouslySetInnerHTML: {
				__html: stripHtmlTags(val?.replace(/(?:\r\n|\r|\n)/g, "") || "")
			}
		});
	}
};

export const encodeFormNames = formName => {
	return formName
		.replaceAll(".", "<FullStop>")
		.replaceAll("[", "<OpenSquareBracket>")
		.replaceAll("]", "<CloseSquareBracket>");
};

export const decodeFormNames = formName => {
	return formName
		.replaceAll("<FullStop>", ".")
		.replaceAll("<OpenSquareBracket>", "[")
		.replaceAll("<CloseSquareBracket>", "]");
};

export const encodeTicketFormNames = tickets => {
	return tickets.map(ticket => {
		ticket.forms = ticket.forms.map(form => {
			if (form.form_name) {
				return form;
			}
		});
		return ticket;
	});
};

export const decodeTicketFormNames = tickets => {
	return tickets.map(ticket => {
		ticket.forms = ticket.forms.map(form => {
			if (form.form_name) {
				return form;
			}
		});
		return ticket;
	});
};
export const copyToClipboard = section => {
	navigator.clipboard.writeText(section);
	successToaster("Copied to Clipboard");
};

export const CurrencyWithAmount = ({
	currencyName,
	number,
	removeNegativeFromNumber = false,
	className,
	style,
	fontSize
}) => {
	//return currency with an number having comas based on the region, with a space between them, up to 2 decimal places. Example: ₹ 16,315.26
	const rawNumber =
		typeof number === "string" && number.includes("*")
			? parseFloat(number.replace("*", ""))
			: number;

	const currencyLocaleMap = {
		AED: "ar-AE", // United Arab Emirates
		USD: "en-US", // United States
		AMD: "hy-AM", // Armenia
		ARS: "es-AR", // Argentina
		AUD: "en-AU", // Australia
		AWG: "nl-AW", // Aruba
		BBD: "en-BB", // Barbados
		BDT: "bn-BD", // Bangladesh
		BMD: "en-BM", // Bermuda
		BND: "ms-BN", // Brunei
		BOB: "es-BO", // Bolivia
		BSD: "en-BS", // Bahamas
		BWP: "en-BW", // Botswana
		BZD: "en-BZ", // Belize
		CAD: "en-CA", // Canada
		CHF: "de-CH", // Switzerland
		CNY: "zh-CN", // China
		COP: "es-CO", // Colombia
		CRC: "es-CR", // Costa Rica
		CUP: "es-CU", // Cuba
		CZK: "cs-CZ", // Czech Republic
		DKK: "da-DK", // Denmark
		DOP: "es-DO", // Dominican Republic
		DZD: "ar-DZ", // Algeria
		EGP: "ar-EG", // Egypt
		ETB: "am-ET", // Ethiopia
		EUR: "en-EU", // European Union
		FJD: "en-FJ", // Fiji
		GBP: "en-GB", // United Kingdom
		GIP: "en-GI", // Gibraltar
		GHS: "en-GH", // Ghana
		GMD: "en-GM", // Gambia
		GTQ: "es-GT", // Guatemala
		GYD: "en-GY", // Guyana
		HKD: "en-HK", // Hong Kong
		HNL: "es-HN", // Honduras
		HRK: "hr-HR", // Croatia
		HTG: "ht-HT", // Haiti
		HUF: "hu-HU", // Hungary
		IDR: "id-ID", // Indonesia
		ILS: "he-IL", // Israel
		JMD: "en-JM", // Jamaica
		KES: "en-KE", // Kenya
		KGS: "ky-KG", // Kyrgyzstan
		KHR: "km-KH", // Cambodia
		KYD: "en-KY", // Cayman Islands
		KZT: "ru-KZ", // Kazakhstan
		LAK: "lo-LA", // Laos
		LBP: "ar-LB", // Lebanon
		LKR: "si-LK", // Sri Lanka
		LRD: "en-LR", // Liberia
		LSL: "en-LS", // Lesotho
		MAD: "ar-MA", // Morocco
		MDL: "ro-MD", // Moldova
		MKD: "mk-MK", // North Macedonia
		MMK: "my-MM", // Myanmar
		MNT: "mn-MN", // Mongolia
		MOP: "zh-MO", // Macau
		MUR: "en-MU", // Mauritius
		MVR: "dv-MV", // Maldives
		MWK: "en-MW", // Malawi
		MXN: "es-MX", // Mexico
		MYR: "ms-MY", // Malaysia
		NAD: "af-NA", // Namibia
		NGN: "en-NG", // Nigeria
		NIO: "es-NI", // Nicaragua
		NOK: "no-NO", // Norway
		NPR: "ne-NP", // Nepal
		NZD: "en-NZ", // New Zealand
		PEN: "es-PE", // Peru
		PGK: "en-PG", // Papua New Guinea
		PHP: "fil-PH", // Philippines
		PKR: "ur-PK", // Pakistan
		QAR: "ar-QA", // Qatar
		RUB: "ru-RU", // Russia
		SAR: "ar-SA", // Saudi Arabia
		SCR: "en-SC", // Seychelles
		SEK: "sv-SE", // Sweden
		SGD: "en-SG", // Singapore
		SLL: "en-SL", // Sierra Leone
		SOS: "so-SO", // Somalia
		SSP: "en-SS", // South Sudan
		SVC: "es-SV", // El Salvador
		SZL: "en-SZ", // Eswatini
		THB: "th-TH", // Thailand
		TTD: "en-TT", // Trinidad and Tobago
		TZS: "sw-TZ", // Tanzania
		ALL: "sq-AL", // Albania
		UYU: "es-UY", // Uruguay
		UZS: "uz-UZ", // Uzbekistan
		YER: "ar-YE", // Yemen
		ZAR: "en-ZA" // South Africa
	};

	const listOfCurrencyDifferentFormat = {
		// list of currency where the currency symbol comes after the amount e.g. 2.00 د.إ
		AED: "ar-AE",
		CHF: "de-CH",
		CZK: "cs-CZ",
		DKK: "da-DK",
		HUF: "hu-HU",
		NOK: "nb-NO",
		PLN: "pl-PL",
		SEK: "sv-SE"
	};

	const ifCurrencyExist = () => {
		// return true if currency exist in listOfCurrencyDifferentFormat
		if (Object.keys(listOfCurrencyDifferentFormat).includes(currencyName)) {
			return true;
		}
		return false;
	};

	const listOfNonStandardNumericCurrencies = {
		BDT: "bn-BD",
		EGP: "ar-EG",
		LBP: "ar-LB",
		QAR: "ar-QA",
		SAR: "ar-SA",
		YER: "ar-YE"
	};

	function getLocaleFromCurrency(currency) {
		const upperCaseCurrency = currency?.toUpperCase();
		if (
			Object.prototype.hasOwnProperty.call(
				listOfNonStandardNumericCurrencies,
				upperCaseCurrency
			)
		) {
			return "sv-SE";
		}
		if (Object.prototype.hasOwnProperty.call(currencyLocaleMap, upperCaseCurrency)) {
			return currencyLocaleMap[upperCaseCurrency];
		}
		// Default to en-IN for unknown currencies
		return "en-IN";
	}

	const _number = removeNegativeFromNumber ? Math.abs(rawNumber) : rawNumber; // remove negative sign

	// Convert the input number to a Number object and format it as currency
	const formattedNumber = Number(_number < 0 ? 0 : _number).toLocaleString(
		getLocaleFromCurrency(currencyName),
		{
			maximumFractionDigits: 2, // Set the maximum number of decimal places to 2
			minimumFractionDigits: 2 // Set the minimum number of decimal places to 2
		}
	);

	function formatString(string) {
		// Remove trailing '.00' if present
		if (string.endsWith(".00")) {
			return string.slice(0, -3); // Remove last 3 characters
		}
		return string; // Otherwise return the string as is
	}

	// return `${currencyName === "AUD" ? "A" : ""}${getSymbolFromCurrency(currencyName)} ${formattedNumber}`
	return (
		<>
			{ifCurrencyExist(currencyName) ? (
				<>
					<span style={{ marginRight: "3.5px" }}>{formatString(formattedNumber)}</span>
					<GetCurrency
						currency={currencyName}
						className={className}
						style={style}
						fontSize={fontSize}
					/>
					{typeof number === "string" && number.includes("*") && "*"}
				</>
			) : (
				<>
					<GetCurrency
						currency={currencyName}
						className={className}
						style={style}
						fontSize={fontSize}
					/>
					<span style={{ marginLeft: "3.5px" }}>{formatString(formattedNumber)}</span>
					{typeof number === "string" && number.includes("*") && "*"}
				</>
			)}
		</>
	);
};

export const addAttributesToLinks = (htmlString) => {
	return htmlString.replace(
		/<a\s+([^>]*)href=["']([^"']*)["']([^>]*)>/gi,
		(match, beforeHref, href, afterHref) => {
			const updatedAttributes = `${beforeHref} ${afterHref}`
				.replace(/\btarget=["'][^"']*["']/gi, "")
				.replace(/\brel=["'][^"']*["']/gi, "")
				.trim();

			return `<a ${updatedAttributes} href="${href}" target="_blank" rel="noopener noreferrer">`;
		}
	);
};


// Function to determine if the background color is dark
export const isDarkColor = color => {
	const getLuminance = color => {
		const rgb = parseInt(color, 16); // convert hex to RGB
		const r = (rgb >> 16) & 0xff;
		const g = (rgb >> 8) & 0xff;
		const b = (rgb >> 0) & 0xff;

		// Calculate luminance using the standard formula
		const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
		return luminance;
	};

	return getLuminance(color) < 140;
};

// Phone validation helper function
export function validatePhone(
	value,
	isMandatory,
	isVisible,
	dialCode,
	waDialCode,
	formType,
	formName,
	waConsents
) {
	let errorMessage = "";

	function startsWithZero(phone) {
		return phone && phone.startsWith("0");
	}

	function isValidLength(phone, minLength, maxLength) {
		return phone && phone.length >= minLength && phone.length <= maxLength;
	}

	if (isMandatory && isVisible) {
		if (!value || value === undefined) {
			errorMessage = `Please enter your ${formName}`;
		} else if (isNaN(value)) {
			errorMessage = `${formName} should only contain numbers`;
		} else if (!isValidLength(value.toString(), 5, 15)) {
			errorMessage = `${formName} should be between 5 and 15 digits`;
		} else if (dialCode === "+91" && startsWithZero(value)) {
			errorMessage = `Invalid ${formName}. ${formName}s cannot start with 0.`;
		}
	} else if (value) {
		if (isNaN(value)) {
			errorMessage = `${formName} should only contain numbers`;
		} else if (!isValidLength(value.toString(), 5, 15)) {
			errorMessage = `${formName} should be between 5 and 15 digits`;
		} else if (dialCode === "+91" && startsWithZero(value)) {
			errorMessage = `Invalid ${formName}. ${formName}s cannot start with 0.`;
		}
	}

	// Separate validation for the WhatsApp number, only if waConsents is true
	if (waConsents) {
		if (!value || value === undefined) {
			errorMessage = `Please enter your ${formName}`;
		} else if (isNaN(value)) {
			errorMessage = `${formName} should only contain numbers`;
		} else if (!isValidLength(value.toString(), 5, 15)) {
			errorMessage = `${formName} should be between 5 and 15 digits`;
		} else if (waDialCode === "+91" && startsWithZero(value)) {
			errorMessage = `Invalid ${formName}. Numbers cannot start with 0.`;
		}
	}

	return errorMessage;
}

// LinkedIn URL validation helper function
export function validateLinkedInUrl(value, isMandatory, isVisible, formName) {
	let errorMessage = "";

	function isLinkedInUrl(url) {
		return url && url.includes("linkedin.com");
	}

	function hasValidPrefix(url) {
		return url && (url.startsWith("https://") || url.startsWith("http://"));
	}

	if (isMandatory && isVisible) {
		if (!value) {
			errorMessage = `Please enter a ${formName}`;
		} else if (!hasValidPrefix(value)) {
			errorMessage = "Please enter a valid URL (also add https:// or http:// prefix)";
		} else if (!isLinkedInUrl(value)) {
			errorMessage = "Enter a valid LinkedIn URL";
		}
	} else {
		if (value) {
			if (!hasValidPrefix(value)) {
				errorMessage = "Please enter a valid URL (also add https:// or http:// prefix)";
			} else if (!isLinkedInUrl(value)) {
				errorMessage = "Enter a valid LinkedIn URL";
			}
		}
	}

	return errorMessage;
}

export const handleOnLogin = () => {
	try {
		document.cookie.split(";").forEach((cookie) => {
			if (cookie.trim().startsWith("CognitoIdentityServiceProvider")) {
				document.cookie = cookie.replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/;domain=konfhub.com");
			}
		});
		Cookies.remove("authToken");
		let cookies = Cookies.get();
		Object.keys(cookies).map(key => {
			if (key.includes("CognitoIdentityServiceProvider")) Cookies.remove(key);
		});
		handleLogout();
		window.location.href = `${loginUrl}/login?redirect=Konfhub`;
	} catch (e) {
		console.log(e)
	}
}